



































import {
  computed, defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { CategoryTree, Maybe } from '~/modules/GraphQL/types';

import List from '../List/List.vue';

interface MenuTree extends CategoryTree {
  is_hidden_in_nav?: boolean,
  children: Maybe<Array<Maybe<MenuTree>>>
}

export default defineComponent({
  name: 'SubMenu',
  components: {
    SvgImage,
    List,
  },
  props: {
    currentCategory: {
      type: Object as PropType<MenuTree | null>,
      default: () => null,
    },
    categoryUid: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const categoryShortNames = {
      'Ink Cartridges': 'Ink',
      'Toner Cartridges': 'Toner',
      'Label Tape': 'Label tape',
    };
    const showedAll = ref<boolean>(false);
    const sortedCategories = computed(() => {
      const categories = props.currentCategory?.children ? [...props.currentCategory.children] : [];

      return categories.sort((a, b) => a.name.localeCompare(b.name));
    });
    const visibleItems = computed(() => sortedCategories.value.filter((category) => !category.is_hidden_in_nav));
    const hiddenItems = computed(() => sortedCategories.value.filter((category) => category.is_hidden_in_nav));
    const shortCategoryName = computed(() => categoryShortNames[props.currentCategory.name] || '');
    const isShown = computed(() => {
      const isEqualUid = props.categoryUid ? props.categoryUid === props.currentCategory?.uid : false;

      return isEqualUid && props.currentCategory?.children?.length;
    });

    const handleShowedAll = () => {
      showedAll.value = !showedAll.value;
    };

    return {
      showedAll,
      visibleItems,
      hiddenItems,
      shortCategoryName,
      isShown,
      handleShowedAll,
    };
  },
});
